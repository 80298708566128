import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  LoginOutput: { input: any; output: any; }
  timestamptz: { input: any; output: any; }
};

/** Boolean expression to compare columns of type "Boolean". All fields are combined with logical 'AND'. */
export type Boolean_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['Boolean']['input']>;
  _gt?: InputMaybe<Scalars['Boolean']['input']>;
  _gte?: InputMaybe<Scalars['Boolean']['input']>;
  _in?: InputMaybe<Array<Scalars['Boolean']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['Boolean']['input']>;
  _lte?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<Scalars['Boolean']['input']>;
  _nin?: InputMaybe<Array<Scalars['Boolean']['input']>>;
};

/** Boolean expression to compare columns of type "Int". All fields are combined with logical 'AND'. */
export type Int_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['Int']['input']>;
  _gt?: InputMaybe<Scalars['Int']['input']>;
  _gte?: InputMaybe<Scalars['Int']['input']>;
  _in?: InputMaybe<Array<Scalars['Int']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['Int']['input']>;
  _lte?: InputMaybe<Scalars['Int']['input']>;
  _neq?: InputMaybe<Scalars['Int']['input']>;
  _nin?: InputMaybe<Array<Scalars['Int']['input']>>;
};

/** Boolean expression to compare columns of type "String". All fields are combined with logical 'AND'. */
export type String_Array_Comparison_Exp = {
  /** is the array contained in the given array value */
  _contained_in?: InputMaybe<Array<Scalars['String']['input']>>;
  /** does the array contain the given value */
  _contains?: InputMaybe<Array<Scalars['String']['input']>>;
  _eq?: InputMaybe<Array<Scalars['String']['input']>>;
  _gt?: InputMaybe<Array<Scalars['String']['input']>>;
  _gte?: InputMaybe<Array<Scalars['String']['input']>>;
  _in?: InputMaybe<Array<Array<Scalars['String']['input']>>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Array<Scalars['String']['input']>>;
  _lte?: InputMaybe<Array<Scalars['String']['input']>>;
  _neq?: InputMaybe<Array<Scalars['String']['input']>>;
  _nin?: InputMaybe<Array<Array<Scalars['String']['input']>>>;
};

/** Boolean expression to compare columns of type "String". All fields are combined with logical 'AND'. */
export type String_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['String']['input']>;
  _gt?: InputMaybe<Scalars['String']['input']>;
  _gte?: InputMaybe<Scalars['String']['input']>;
  /** does the column match the given case-insensitive pattern */
  _ilike?: InputMaybe<Scalars['String']['input']>;
  _in?: InputMaybe<Array<Scalars['String']['input']>>;
  /** does the column match the given POSIX regular expression, case insensitive */
  _iregex?: InputMaybe<Scalars['String']['input']>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  /** does the column match the given pattern */
  _like?: InputMaybe<Scalars['String']['input']>;
  _lt?: InputMaybe<Scalars['String']['input']>;
  _lte?: InputMaybe<Scalars['String']['input']>;
  _neq?: InputMaybe<Scalars['String']['input']>;
  /** does the column NOT match the given case-insensitive pattern */
  _nilike?: InputMaybe<Scalars['String']['input']>;
  _nin?: InputMaybe<Array<Scalars['String']['input']>>;
  /** does the column NOT match the given POSIX regular expression, case insensitive */
  _niregex?: InputMaybe<Scalars['String']['input']>;
  /** does the column NOT match the given pattern */
  _nlike?: InputMaybe<Scalars['String']['input']>;
  /** does the column NOT match the given POSIX regular expression, case sensitive */
  _nregex?: InputMaybe<Scalars['String']['input']>;
  /** does the column NOT match the given SQL regular expression */
  _nsimilar?: InputMaybe<Scalars['String']['input']>;
  /** does the column match the given POSIX regular expression, case sensitive */
  _regex?: InputMaybe<Scalars['String']['input']>;
  /** does the column match the given SQL regular expression */
  _similar?: InputMaybe<Scalars['String']['input']>;
};

/** columns and relationships of "blogs" */
export type Blogs = {
  __typename?: 'blogs';
  category?: Maybe<Scalars['String']['output']>;
  content: Scalars['String']['output'];
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  id: Scalars['Int']['output'];
  shortContent?: Maybe<Scalars['String']['output']>;
  /** A computed field, executes function "get_blog_thumbnail" */
  thumbnail?: Maybe<Array<Images>>;
  title?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['timestamptz']['output']>;
};


/** columns and relationships of "blogs" */
export type BlogsThumbnailArgs = {
  distinct_on?: InputMaybe<Array<Images_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Images_Order_By>>;
  where?: InputMaybe<Images_Bool_Exp>;
};

/** aggregated selection of "blogs" */
export type Blogs_Aggregate = {
  __typename?: 'blogs_aggregate';
  aggregate?: Maybe<Blogs_Aggregate_Fields>;
  nodes: Array<Blogs>;
};

/** aggregate fields of "blogs" */
export type Blogs_Aggregate_Fields = {
  __typename?: 'blogs_aggregate_fields';
  avg?: Maybe<Blogs_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Blogs_Max_Fields>;
  min?: Maybe<Blogs_Min_Fields>;
  stddev?: Maybe<Blogs_Stddev_Fields>;
  stddev_pop?: Maybe<Blogs_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Blogs_Stddev_Samp_Fields>;
  sum?: Maybe<Blogs_Sum_Fields>;
  var_pop?: Maybe<Blogs_Var_Pop_Fields>;
  var_samp?: Maybe<Blogs_Var_Samp_Fields>;
  variance?: Maybe<Blogs_Variance_Fields>;
};


/** aggregate fields of "blogs" */
export type Blogs_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Blogs_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type Blogs_Avg_Fields = {
  __typename?: 'blogs_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "blogs". All fields are combined with a logical 'AND'. */
export type Blogs_Bool_Exp = {
  _and?: InputMaybe<Array<Blogs_Bool_Exp>>;
  _not?: InputMaybe<Blogs_Bool_Exp>;
  _or?: InputMaybe<Array<Blogs_Bool_Exp>>;
  category?: InputMaybe<String_Comparison_Exp>;
  content?: InputMaybe<String_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  shortContent?: InputMaybe<String_Comparison_Exp>;
  thumbnail?: InputMaybe<Images_Bool_Exp>;
  title?: InputMaybe<String_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** aggregate max on columns */
export type Blogs_Max_Fields = {
  __typename?: 'blogs_max_fields';
  category?: Maybe<Scalars['String']['output']>;
  content?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  shortContent?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['timestamptz']['output']>;
};

/** aggregate min on columns */
export type Blogs_Min_Fields = {
  __typename?: 'blogs_min_fields';
  category?: Maybe<Scalars['String']['output']>;
  content?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  shortContent?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['timestamptz']['output']>;
};

/** Ordering options when selecting data from "blogs". */
export type Blogs_Order_By = {
  category?: InputMaybe<Order_By>;
  content?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  shortContent?: InputMaybe<Order_By>;
  thumbnail_aggregate?: InputMaybe<Images_Aggregate_Order_By>;
  title?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** select columns of table "blogs" */
export enum Blogs_Select_Column {
  /** column name */
  Category = 'category',
  /** column name */
  Content = 'content',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  ShortContent = 'shortContent',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** aggregate stddev on columns */
export type Blogs_Stddev_Fields = {
  __typename?: 'blogs_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Blogs_Stddev_Pop_Fields = {
  __typename?: 'blogs_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Blogs_Stddev_Samp_Fields = {
  __typename?: 'blogs_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "blogs" */
export type Blogs_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Blogs_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Blogs_Stream_Cursor_Value_Input = {
  category?: InputMaybe<Scalars['String']['input']>;
  content?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  shortContent?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate sum on columns */
export type Blogs_Sum_Fields = {
  __typename?: 'blogs_sum_fields';
  id?: Maybe<Scalars['Int']['output']>;
};

/** aggregate var_pop on columns */
export type Blogs_Var_Pop_Fields = {
  __typename?: 'blogs_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Blogs_Var_Samp_Fields = {
  __typename?: 'blogs_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Blogs_Variance_Fields = {
  __typename?: 'blogs_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** ordering argument of a cursor */
export enum Cursor_Ordering {
  /** ascending ordering of the cursor */
  Asc = 'ASC',
  /** descending ordering of the cursor */
  Desc = 'DESC'
}

/** columns and relationships of "hirings" */
export type Hirings = {
  __typename?: 'hirings';
  content?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  description: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  /** A computed field, executes function "get_hiring_image" */
  image?: Maybe<Array<Images>>;
  link?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['timestamptz']['output']>;
};


/** columns and relationships of "hirings" */
export type HiringsImageArgs = {
  distinct_on?: InputMaybe<Array<Images_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Images_Order_By>>;
  where?: InputMaybe<Images_Bool_Exp>;
};

/** aggregated selection of "hirings" */
export type Hirings_Aggregate = {
  __typename?: 'hirings_aggregate';
  aggregate?: Maybe<Hirings_Aggregate_Fields>;
  nodes: Array<Hirings>;
};

/** aggregate fields of "hirings" */
export type Hirings_Aggregate_Fields = {
  __typename?: 'hirings_aggregate_fields';
  avg?: Maybe<Hirings_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Hirings_Max_Fields>;
  min?: Maybe<Hirings_Min_Fields>;
  stddev?: Maybe<Hirings_Stddev_Fields>;
  stddev_pop?: Maybe<Hirings_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Hirings_Stddev_Samp_Fields>;
  sum?: Maybe<Hirings_Sum_Fields>;
  var_pop?: Maybe<Hirings_Var_Pop_Fields>;
  var_samp?: Maybe<Hirings_Var_Samp_Fields>;
  variance?: Maybe<Hirings_Variance_Fields>;
};


/** aggregate fields of "hirings" */
export type Hirings_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Hirings_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type Hirings_Avg_Fields = {
  __typename?: 'hirings_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "hirings". All fields are combined with a logical 'AND'. */
export type Hirings_Bool_Exp = {
  _and?: InputMaybe<Array<Hirings_Bool_Exp>>;
  _not?: InputMaybe<Hirings_Bool_Exp>;
  _or?: InputMaybe<Array<Hirings_Bool_Exp>>;
  content?: InputMaybe<String_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  image?: InputMaybe<Images_Bool_Exp>;
  link?: InputMaybe<String_Comparison_Exp>;
  title?: InputMaybe<String_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** aggregate max on columns */
export type Hirings_Max_Fields = {
  __typename?: 'hirings_max_fields';
  content?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  link?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['timestamptz']['output']>;
};

/** aggregate min on columns */
export type Hirings_Min_Fields = {
  __typename?: 'hirings_min_fields';
  content?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  link?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['timestamptz']['output']>;
};

/** Ordering options when selecting data from "hirings". */
export type Hirings_Order_By = {
  content?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  image_aggregate?: InputMaybe<Images_Aggregate_Order_By>;
  link?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** select columns of table "hirings" */
export enum Hirings_Select_Column {
  /** column name */
  Content = 'content',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  Link = 'link',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** aggregate stddev on columns */
export type Hirings_Stddev_Fields = {
  __typename?: 'hirings_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Hirings_Stddev_Pop_Fields = {
  __typename?: 'hirings_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Hirings_Stddev_Samp_Fields = {
  __typename?: 'hirings_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "hirings" */
export type Hirings_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Hirings_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Hirings_Stream_Cursor_Value_Input = {
  content?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  link?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate sum on columns */
export type Hirings_Sum_Fields = {
  __typename?: 'hirings_sum_fields';
  id?: Maybe<Scalars['Int']['output']>;
};

/** aggregate var_pop on columns */
export type Hirings_Var_Pop_Fields = {
  __typename?: 'hirings_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Hirings_Var_Samp_Fields = {
  __typename?: 'hirings_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Hirings_Variance_Fields = {
  __typename?: 'hirings_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "image_subtypes" */
export type Image_Subtypes = {
  __typename?: 'image_subtypes';
  description: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

/** aggregated selection of "image_subtypes" */
export type Image_Subtypes_Aggregate = {
  __typename?: 'image_subtypes_aggregate';
  aggregate?: Maybe<Image_Subtypes_Aggregate_Fields>;
  nodes: Array<Image_Subtypes>;
};

/** aggregate fields of "image_subtypes" */
export type Image_Subtypes_Aggregate_Fields = {
  __typename?: 'image_subtypes_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Image_Subtypes_Max_Fields>;
  min?: Maybe<Image_Subtypes_Min_Fields>;
};


/** aggregate fields of "image_subtypes" */
export type Image_Subtypes_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Image_Subtypes_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "image_subtypes". All fields are combined with a logical 'AND'. */
export type Image_Subtypes_Bool_Exp = {
  _and?: InputMaybe<Array<Image_Subtypes_Bool_Exp>>;
  _not?: InputMaybe<Image_Subtypes_Bool_Exp>;
  _or?: InputMaybe<Array<Image_Subtypes_Bool_Exp>>;
  description?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "image_subtypes" */
export enum Image_Subtypes_Constraint {
  /** unique or primary key constraint on columns "value" */
  ImageSubtypesPkey = 'image_subtypes_pkey'
}

/** input type for inserting data into table "image_subtypes" */
export type Image_Subtypes_Insert_Input = {
  description?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Image_Subtypes_Max_Fields = {
  __typename?: 'image_subtypes_max_fields';
  description?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type Image_Subtypes_Min_Fields = {
  __typename?: 'image_subtypes_min_fields';
  description?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "image_subtypes" */
export type Image_Subtypes_Mutation_Response = {
  __typename?: 'image_subtypes_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Image_Subtypes>;
};

/** on_conflict condition type for table "image_subtypes" */
export type Image_Subtypes_On_Conflict = {
  constraint: Image_Subtypes_Constraint;
  update_columns?: Array<Image_Subtypes_Update_Column>;
  where?: InputMaybe<Image_Subtypes_Bool_Exp>;
};

/** Ordering options when selecting data from "image_subtypes". */
export type Image_Subtypes_Order_By = {
  description?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: image_subtypes */
export type Image_Subtypes_Pk_Columns_Input = {
  value: Scalars['String']['input'];
};

/** select columns of table "image_subtypes" */
export enum Image_Subtypes_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "image_subtypes" */
export type Image_Subtypes_Set_Input = {
  description?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "image_subtypes" */
export type Image_Subtypes_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Image_Subtypes_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Image_Subtypes_Stream_Cursor_Value_Input = {
  description?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "image_subtypes" */
export enum Image_Subtypes_Update_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value'
}

export type Image_Subtypes_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Image_Subtypes_Set_Input>;
  /** filter the rows which have to be updated */
  where: Image_Subtypes_Bool_Exp;
};

/** columns and relationships of "image_types" */
export type Image_Types = {
  __typename?: 'image_types';
  description: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

/** aggregated selection of "image_types" */
export type Image_Types_Aggregate = {
  __typename?: 'image_types_aggregate';
  aggregate?: Maybe<Image_Types_Aggregate_Fields>;
  nodes: Array<Image_Types>;
};

/** aggregate fields of "image_types" */
export type Image_Types_Aggregate_Fields = {
  __typename?: 'image_types_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Image_Types_Max_Fields>;
  min?: Maybe<Image_Types_Min_Fields>;
};


/** aggregate fields of "image_types" */
export type Image_Types_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Image_Types_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "image_types". All fields are combined with a logical 'AND'. */
export type Image_Types_Bool_Exp = {
  _and?: InputMaybe<Array<Image_Types_Bool_Exp>>;
  _not?: InputMaybe<Image_Types_Bool_Exp>;
  _or?: InputMaybe<Array<Image_Types_Bool_Exp>>;
  description?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "image_types" */
export enum Image_Types_Constraint {
  /** unique or primary key constraint on columns "value" */
  ImageTypesPkey = 'image_types_pkey'
}

/** input type for inserting data into table "image_types" */
export type Image_Types_Insert_Input = {
  description?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Image_Types_Max_Fields = {
  __typename?: 'image_types_max_fields';
  description?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type Image_Types_Min_Fields = {
  __typename?: 'image_types_min_fields';
  description?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "image_types" */
export type Image_Types_Mutation_Response = {
  __typename?: 'image_types_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Image_Types>;
};

/** on_conflict condition type for table "image_types" */
export type Image_Types_On_Conflict = {
  constraint: Image_Types_Constraint;
  update_columns?: Array<Image_Types_Update_Column>;
  where?: InputMaybe<Image_Types_Bool_Exp>;
};

/** Ordering options when selecting data from "image_types". */
export type Image_Types_Order_By = {
  description?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: image_types */
export type Image_Types_Pk_Columns_Input = {
  value: Scalars['String']['input'];
};

/** select columns of table "image_types" */
export enum Image_Types_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "image_types" */
export type Image_Types_Set_Input = {
  description?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "image_types" */
export type Image_Types_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Image_Types_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Image_Types_Stream_Cursor_Value_Input = {
  description?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "image_types" */
export enum Image_Types_Update_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value'
}

export type Image_Types_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Image_Types_Set_Input>;
  /** filter the rows which have to be updated */
  where: Image_Types_Bool_Exp;
};

/** columns and relationships of "images" */
export type Images = {
  __typename?: 'images';
  filename?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  objectId: Scalars['Int']['output'];
  subtype: Scalars['String']['output'];
  type: Scalars['String']['output'];
};

/** aggregated selection of "images" */
export type Images_Aggregate = {
  __typename?: 'images_aggregate';
  aggregate?: Maybe<Images_Aggregate_Fields>;
  nodes: Array<Images>;
};

/** aggregate fields of "images" */
export type Images_Aggregate_Fields = {
  __typename?: 'images_aggregate_fields';
  avg?: Maybe<Images_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Images_Max_Fields>;
  min?: Maybe<Images_Min_Fields>;
  stddev?: Maybe<Images_Stddev_Fields>;
  stddev_pop?: Maybe<Images_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Images_Stddev_Samp_Fields>;
  sum?: Maybe<Images_Sum_Fields>;
  var_pop?: Maybe<Images_Var_Pop_Fields>;
  var_samp?: Maybe<Images_Var_Samp_Fields>;
  variance?: Maybe<Images_Variance_Fields>;
};


/** aggregate fields of "images" */
export type Images_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Images_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "images" */
export type Images_Aggregate_Order_By = {
  avg?: InputMaybe<Images_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Images_Max_Order_By>;
  min?: InputMaybe<Images_Min_Order_By>;
  stddev?: InputMaybe<Images_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Images_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Images_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Images_Sum_Order_By>;
  var_pop?: InputMaybe<Images_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Images_Var_Samp_Order_By>;
  variance?: InputMaybe<Images_Variance_Order_By>;
};

/** aggregate avg on columns */
export type Images_Avg_Fields = {
  __typename?: 'images_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
  objectId?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "images" */
export type Images_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  objectId?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "images". All fields are combined with a logical 'AND'. */
export type Images_Bool_Exp = {
  _and?: InputMaybe<Array<Images_Bool_Exp>>;
  _not?: InputMaybe<Images_Bool_Exp>;
  _or?: InputMaybe<Array<Images_Bool_Exp>>;
  filename?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  objectId?: InputMaybe<Int_Comparison_Exp>;
  subtype?: InputMaybe<String_Comparison_Exp>;
  type?: InputMaybe<String_Comparison_Exp>;
};

/** aggregate max on columns */
export type Images_Max_Fields = {
  __typename?: 'images_max_fields';
  filename?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  objectId?: Maybe<Scalars['Int']['output']>;
  subtype?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "images" */
export type Images_Max_Order_By = {
  filename?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  objectId?: InputMaybe<Order_By>;
  subtype?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Images_Min_Fields = {
  __typename?: 'images_min_fields';
  filename?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  objectId?: Maybe<Scalars['Int']['output']>;
  subtype?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "images" */
export type Images_Min_Order_By = {
  filename?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  objectId?: InputMaybe<Order_By>;
  subtype?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
};

/** Ordering options when selecting data from "images". */
export type Images_Order_By = {
  filename?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  objectId?: InputMaybe<Order_By>;
  subtype?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
};

/** select columns of table "images" */
export enum Images_Select_Column {
  /** column name */
  Filename = 'filename',
  /** column name */
  Id = 'id',
  /** column name */
  ObjectId = 'objectId',
  /** column name */
  Subtype = 'subtype',
  /** column name */
  Type = 'type'
}

/** aggregate stddev on columns */
export type Images_Stddev_Fields = {
  __typename?: 'images_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
  objectId?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "images" */
export type Images_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  objectId?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Images_Stddev_Pop_Fields = {
  __typename?: 'images_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  objectId?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "images" */
export type Images_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  objectId?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Images_Stddev_Samp_Fields = {
  __typename?: 'images_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  objectId?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "images" */
export type Images_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  objectId?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "images" */
export type Images_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Images_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Images_Stream_Cursor_Value_Input = {
  filename?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  objectId?: InputMaybe<Scalars['Int']['input']>;
  subtype?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type Images_Sum_Fields = {
  __typename?: 'images_sum_fields';
  id?: Maybe<Scalars['Int']['output']>;
  objectId?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "images" */
export type Images_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  objectId?: InputMaybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Images_Var_Pop_Fields = {
  __typename?: 'images_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  objectId?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "images" */
export type Images_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  objectId?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Images_Var_Samp_Fields = {
  __typename?: 'images_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  objectId?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "images" */
export type Images_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  objectId?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Images_Variance_Fields = {
  __typename?: 'images_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
  objectId?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "images" */
export type Images_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  objectId?: InputMaybe<Order_By>;
};

/** columns and relationships of "kols" */
export type Kols = {
  __typename?: 'kols';
  /** A computed field, executes function "get_kol_thumbnail" */
  avatar?: Maybe<Array<Images>>;
  campaign?: Maybe<Scalars['String']['output']>;
  content: Scalars['String']['output'];
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  isShow: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['timestamptz']['output']>;
};


/** columns and relationships of "kols" */
export type KolsAvatarArgs = {
  distinct_on?: InputMaybe<Array<Images_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Images_Order_By>>;
  where?: InputMaybe<Images_Bool_Exp>;
};

/** aggregated selection of "kols" */
export type Kols_Aggregate = {
  __typename?: 'kols_aggregate';
  aggregate?: Maybe<Kols_Aggregate_Fields>;
  nodes: Array<Kols>;
};

/** aggregate fields of "kols" */
export type Kols_Aggregate_Fields = {
  __typename?: 'kols_aggregate_fields';
  avg?: Maybe<Kols_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Kols_Max_Fields>;
  min?: Maybe<Kols_Min_Fields>;
  stddev?: Maybe<Kols_Stddev_Fields>;
  stddev_pop?: Maybe<Kols_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Kols_Stddev_Samp_Fields>;
  sum?: Maybe<Kols_Sum_Fields>;
  var_pop?: Maybe<Kols_Var_Pop_Fields>;
  var_samp?: Maybe<Kols_Var_Samp_Fields>;
  variance?: Maybe<Kols_Variance_Fields>;
};


/** aggregate fields of "kols" */
export type Kols_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Kols_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type Kols_Avg_Fields = {
  __typename?: 'kols_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "kols". All fields are combined with a logical 'AND'. */
export type Kols_Bool_Exp = {
  _and?: InputMaybe<Array<Kols_Bool_Exp>>;
  _not?: InputMaybe<Kols_Bool_Exp>;
  _or?: InputMaybe<Array<Kols_Bool_Exp>>;
  avatar?: InputMaybe<Images_Bool_Exp>;
  campaign?: InputMaybe<String_Comparison_Exp>;
  content?: InputMaybe<String_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  email?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  isShow?: InputMaybe<Boolean_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "kols" */
export enum Kols_Constraint {
  /** unique or primary key constraint on columns "id" */
  KolPkey = 'kol_pkey'
}

/** input type for inserting data into table "kols" */
export type Kols_Insert_Input = {
  campaign?: InputMaybe<Scalars['String']['input']>;
  content?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  isShow?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate max on columns */
export type Kols_Max_Fields = {
  __typename?: 'kols_max_fields';
  campaign?: Maybe<Scalars['String']['output']>;
  content?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['timestamptz']['output']>;
};

/** aggregate min on columns */
export type Kols_Min_Fields = {
  __typename?: 'kols_min_fields';
  campaign?: Maybe<Scalars['String']['output']>;
  content?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['timestamptz']['output']>;
};

/** response of any mutation on the table "kols" */
export type Kols_Mutation_Response = {
  __typename?: 'kols_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Kols>;
};

/** on_conflict condition type for table "kols" */
export type Kols_On_Conflict = {
  constraint: Kols_Constraint;
  update_columns?: Array<Kols_Update_Column>;
  where?: InputMaybe<Kols_Bool_Exp>;
};

/** Ordering options when selecting data from "kols". */
export type Kols_Order_By = {
  avatar_aggregate?: InputMaybe<Images_Aggregate_Order_By>;
  campaign?: InputMaybe<Order_By>;
  content?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  isShow?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** select columns of table "kols" */
export enum Kols_Select_Column {
  /** column name */
  Campaign = 'campaign',
  /** column name */
  Content = 'content',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Email = 'email',
  /** column name */
  Id = 'id',
  /** column name */
  IsShow = 'isShow',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** aggregate stddev on columns */
export type Kols_Stddev_Fields = {
  __typename?: 'kols_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Kols_Stddev_Pop_Fields = {
  __typename?: 'kols_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Kols_Stddev_Samp_Fields = {
  __typename?: 'kols_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "kols" */
export type Kols_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Kols_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Kols_Stream_Cursor_Value_Input = {
  campaign?: InputMaybe<Scalars['String']['input']>;
  content?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  isShow?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate sum on columns */
export type Kols_Sum_Fields = {
  __typename?: 'kols_sum_fields';
  id?: Maybe<Scalars['Int']['output']>;
};

/** placeholder for update columns of table "kols" (current role has no relevant permissions) */
export enum Kols_Update_Column {
  /** placeholder (do not use) */
  Placeholder = '_PLACEHOLDER'
}

/** aggregate var_pop on columns */
export type Kols_Var_Pop_Fields = {
  __typename?: 'kols_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Kols_Var_Samp_Fields = {
  __typename?: 'kols_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Kols_Variance_Fields = {
  __typename?: 'kols_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "member_categories" */
export type MemberCategories = {
  __typename?: 'memberCategories';
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  order?: Maybe<Scalars['Int']['output']>;
  updatedAt?: Maybe<Scalars['timestamptz']['output']>;
};

/** aggregated selection of "member_categories" */
export type MemberCategories_Aggregate = {
  __typename?: 'memberCategories_aggregate';
  aggregate?: Maybe<MemberCategories_Aggregate_Fields>;
  nodes: Array<MemberCategories>;
};

/** aggregate fields of "member_categories" */
export type MemberCategories_Aggregate_Fields = {
  __typename?: 'memberCategories_aggregate_fields';
  avg?: Maybe<MemberCategories_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<MemberCategories_Max_Fields>;
  min?: Maybe<MemberCategories_Min_Fields>;
  stddev?: Maybe<MemberCategories_Stddev_Fields>;
  stddev_pop?: Maybe<MemberCategories_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<MemberCategories_Stddev_Samp_Fields>;
  sum?: Maybe<MemberCategories_Sum_Fields>;
  var_pop?: Maybe<MemberCategories_Var_Pop_Fields>;
  var_samp?: Maybe<MemberCategories_Var_Samp_Fields>;
  variance?: Maybe<MemberCategories_Variance_Fields>;
};


/** aggregate fields of "member_categories" */
export type MemberCategories_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<MemberCategories_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type MemberCategories_Avg_Fields = {
  __typename?: 'memberCategories_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
  order?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "member_categories". All fields are combined with a logical 'AND'. */
export type MemberCategories_Bool_Exp = {
  _and?: InputMaybe<Array<MemberCategories_Bool_Exp>>;
  _not?: InputMaybe<MemberCategories_Bool_Exp>;
  _or?: InputMaybe<Array<MemberCategories_Bool_Exp>>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  order?: InputMaybe<Int_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** aggregate max on columns */
export type MemberCategories_Max_Fields = {
  __typename?: 'memberCategories_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  order?: Maybe<Scalars['Int']['output']>;
  updatedAt?: Maybe<Scalars['timestamptz']['output']>;
};

/** aggregate min on columns */
export type MemberCategories_Min_Fields = {
  __typename?: 'memberCategories_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  order?: Maybe<Scalars['Int']['output']>;
  updatedAt?: Maybe<Scalars['timestamptz']['output']>;
};

/** Ordering options when selecting data from "member_categories". */
export type MemberCategories_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** select columns of table "member_categories" */
export enum MemberCategories_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Order = 'order',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** aggregate stddev on columns */
export type MemberCategories_Stddev_Fields = {
  __typename?: 'memberCategories_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
  order?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type MemberCategories_Stddev_Pop_Fields = {
  __typename?: 'memberCategories_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  order?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type MemberCategories_Stddev_Samp_Fields = {
  __typename?: 'memberCategories_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  order?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "memberCategories" */
export type MemberCategories_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: MemberCategories_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type MemberCategories_Stream_Cursor_Value_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Scalars['Int']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate sum on columns */
export type MemberCategories_Sum_Fields = {
  __typename?: 'memberCategories_sum_fields';
  id?: Maybe<Scalars['Int']['output']>;
  order?: Maybe<Scalars['Int']['output']>;
};

/** aggregate var_pop on columns */
export type MemberCategories_Var_Pop_Fields = {
  __typename?: 'memberCategories_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  order?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type MemberCategories_Var_Samp_Fields = {
  __typename?: 'memberCategories_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  order?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type MemberCategories_Variance_Fields = {
  __typename?: 'memberCategories_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
  order?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "members" */
export type Members = {
  __typename?: 'members';
  /** A computed field, executes function "get_member_avatar" */
  avatar?: Maybe<Array<Images>>;
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  id: Scalars['Int']['output'];
  isPin?: Maybe<Scalars['Boolean']['output']>;
  /** An object relationship */
  memberCategory?: Maybe<MemberCategories>;
  memberCategoryId?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  order?: Maybe<Scalars['Int']['output']>;
  role?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['timestamptz']['output']>;
};


/** columns and relationships of "members" */
export type MembersAvatarArgs = {
  distinct_on?: InputMaybe<Array<Images_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Images_Order_By>>;
  where?: InputMaybe<Images_Bool_Exp>;
};

/** aggregated selection of "members" */
export type Members_Aggregate = {
  __typename?: 'members_aggregate';
  aggregate?: Maybe<Members_Aggregate_Fields>;
  nodes: Array<Members>;
};

/** aggregate fields of "members" */
export type Members_Aggregate_Fields = {
  __typename?: 'members_aggregate_fields';
  avg?: Maybe<Members_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Members_Max_Fields>;
  min?: Maybe<Members_Min_Fields>;
  stddev?: Maybe<Members_Stddev_Fields>;
  stddev_pop?: Maybe<Members_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Members_Stddev_Samp_Fields>;
  sum?: Maybe<Members_Sum_Fields>;
  var_pop?: Maybe<Members_Var_Pop_Fields>;
  var_samp?: Maybe<Members_Var_Samp_Fields>;
  variance?: Maybe<Members_Variance_Fields>;
};


/** aggregate fields of "members" */
export type Members_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Members_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type Members_Avg_Fields = {
  __typename?: 'members_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
  memberCategoryId?: Maybe<Scalars['Float']['output']>;
  order?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "members". All fields are combined with a logical 'AND'. */
export type Members_Bool_Exp = {
  _and?: InputMaybe<Array<Members_Bool_Exp>>;
  _not?: InputMaybe<Members_Bool_Exp>;
  _or?: InputMaybe<Array<Members_Bool_Exp>>;
  avatar?: InputMaybe<Images_Bool_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  isPin?: InputMaybe<Boolean_Comparison_Exp>;
  memberCategory?: InputMaybe<MemberCategories_Bool_Exp>;
  memberCategoryId?: InputMaybe<Int_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  order?: InputMaybe<Int_Comparison_Exp>;
  role?: InputMaybe<String_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** aggregate max on columns */
export type Members_Max_Fields = {
  __typename?: 'members_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  memberCategoryId?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  order?: Maybe<Scalars['Int']['output']>;
  role?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['timestamptz']['output']>;
};

/** aggregate min on columns */
export type Members_Min_Fields = {
  __typename?: 'members_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  memberCategoryId?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  order?: Maybe<Scalars['Int']['output']>;
  role?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['timestamptz']['output']>;
};

/** Ordering options when selecting data from "members". */
export type Members_Order_By = {
  avatar_aggregate?: InputMaybe<Images_Aggregate_Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  isPin?: InputMaybe<Order_By>;
  memberCategory?: InputMaybe<MemberCategories_Order_By>;
  memberCategoryId?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
  role?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** select columns of table "members" */
export enum Members_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  IsPin = 'isPin',
  /** column name */
  MemberCategoryId = 'memberCategoryId',
  /** column name */
  Name = 'name',
  /** column name */
  Order = 'order',
  /** column name */
  Role = 'role',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** aggregate stddev on columns */
export type Members_Stddev_Fields = {
  __typename?: 'members_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
  memberCategoryId?: Maybe<Scalars['Float']['output']>;
  order?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Members_Stddev_Pop_Fields = {
  __typename?: 'members_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  memberCategoryId?: Maybe<Scalars['Float']['output']>;
  order?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Members_Stddev_Samp_Fields = {
  __typename?: 'members_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  memberCategoryId?: Maybe<Scalars['Float']['output']>;
  order?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "members" */
export type Members_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Members_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Members_Stream_Cursor_Value_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  isPin?: InputMaybe<Scalars['Boolean']['input']>;
  memberCategoryId?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Scalars['Int']['input']>;
  role?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate sum on columns */
export type Members_Sum_Fields = {
  __typename?: 'members_sum_fields';
  id?: Maybe<Scalars['Int']['output']>;
  memberCategoryId?: Maybe<Scalars['Int']['output']>;
  order?: Maybe<Scalars['Int']['output']>;
};

/** aggregate var_pop on columns */
export type Members_Var_Pop_Fields = {
  __typename?: 'members_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  memberCategoryId?: Maybe<Scalars['Float']['output']>;
  order?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Members_Var_Samp_Fields = {
  __typename?: 'members_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  memberCategoryId?: Maybe<Scalars['Float']['output']>;
  order?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Members_Variance_Fields = {
  __typename?: 'members_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
  memberCategoryId?: Maybe<Scalars['Float']['output']>;
  order?: Maybe<Scalars['Float']['output']>;
};

/** mutation root */
export type Mutation_Root = {
  __typename?: 'mutation_root';
  /** delete data from the table: "image_subtypes" */
  delete_image_subtypes?: Maybe<Image_Subtypes_Mutation_Response>;
  /** delete single row from the table: "image_subtypes" */
  delete_image_subtypes_by_pk?: Maybe<Image_Subtypes>;
  /** delete data from the table: "image_types" */
  delete_image_types?: Maybe<Image_Types_Mutation_Response>;
  /** delete single row from the table: "image_types" */
  delete_image_types_by_pk?: Maybe<Image_Types>;
  /** insert data into the table: "kols" */
  insertKols?: Maybe<Kols_Mutation_Response>;
  /** insert a single row into the table: "kols" */
  insertKolsOne?: Maybe<Kols>;
  /** insert data into the table: "partners" */
  insertPartners?: Maybe<Partners_Mutation_Response>;
  /** insert data into the table: "image_subtypes" */
  insert_image_subtypes?: Maybe<Image_Subtypes_Mutation_Response>;
  /** insert a single row into the table: "image_subtypes" */
  insert_image_subtypes_one?: Maybe<Image_Subtypes>;
  /** insert data into the table: "image_types" */
  insert_image_types?: Maybe<Image_Types_Mutation_Response>;
  /** insert a single row into the table: "image_types" */
  insert_image_types_one?: Maybe<Image_Types>;
  login: Scalars['LoginOutput']['output'];
  /** update data of the table: "image_subtypes" */
  update_image_subtypes?: Maybe<Image_Subtypes_Mutation_Response>;
  /** update single row of the table: "image_subtypes" */
  update_image_subtypes_by_pk?: Maybe<Image_Subtypes>;
  /** update multiples rows of table: "image_subtypes" */
  update_image_subtypes_many?: Maybe<Array<Maybe<Image_Subtypes_Mutation_Response>>>;
  /** update data of the table: "image_types" */
  update_image_types?: Maybe<Image_Types_Mutation_Response>;
  /** update single row of the table: "image_types" */
  update_image_types_by_pk?: Maybe<Image_Types>;
  /** update multiples rows of table: "image_types" */
  update_image_types_many?: Maybe<Array<Maybe<Image_Types_Mutation_Response>>>;
};


/** mutation root */
export type Mutation_RootDelete_Image_SubtypesArgs = {
  where: Image_Subtypes_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Image_Subtypes_By_PkArgs = {
  value: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Image_TypesArgs = {
  where: Image_Types_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Image_Types_By_PkArgs = {
  value: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootInsertKolsArgs = {
  objects: Array<Kols_Insert_Input>;
  on_conflict?: InputMaybe<Kols_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsertKolsOneArgs = {
  object: Kols_Insert_Input;
  on_conflict?: InputMaybe<Kols_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsertPartnersArgs = {
  objects: Array<Partners_Insert_Input>;
  on_conflict?: InputMaybe<Partners_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Image_SubtypesArgs = {
  objects: Array<Image_Subtypes_Insert_Input>;
  on_conflict?: InputMaybe<Image_Subtypes_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Image_Subtypes_OneArgs = {
  object: Image_Subtypes_Insert_Input;
  on_conflict?: InputMaybe<Image_Subtypes_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Image_TypesArgs = {
  objects: Array<Image_Types_Insert_Input>;
  on_conflict?: InputMaybe<Image_Types_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Image_Types_OneArgs = {
  object: Image_Types_Insert_Input;
  on_conflict?: InputMaybe<Image_Types_On_Conflict>;
};


/** mutation root */
export type Mutation_RootLoginArgs = {
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootUpdate_Image_SubtypesArgs = {
  _set?: InputMaybe<Image_Subtypes_Set_Input>;
  where: Image_Subtypes_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Image_Subtypes_By_PkArgs = {
  _set?: InputMaybe<Image_Subtypes_Set_Input>;
  pk_columns: Image_Subtypes_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Image_Subtypes_ManyArgs = {
  updates: Array<Image_Subtypes_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Image_TypesArgs = {
  _set?: InputMaybe<Image_Types_Set_Input>;
  where: Image_Types_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Image_Types_By_PkArgs = {
  _set?: InputMaybe<Image_Types_Set_Input>;
  pk_columns: Image_Types_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Image_Types_ManyArgs = {
  updates: Array<Image_Types_Updates>;
};

/** column ordering options */
export enum Order_By {
  /** in ascending order, nulls last */
  Asc = 'asc',
  /** in ascending order, nulls first */
  AscNullsFirst = 'asc_nulls_first',
  /** in ascending order, nulls last */
  AscNullsLast = 'asc_nulls_last',
  /** in descending order, nulls first */
  Desc = 'desc',
  /** in descending order, nulls first */
  DescNullsFirst = 'desc_nulls_first',
  /** in descending order, nulls last */
  DescNullsLast = 'desc_nulls_last'
}

/** Boolean expression to filter rows from the table "partners". All fields are combined with a logical 'AND'. */
export type Partners_Bool_Exp = {
  _and?: InputMaybe<Array<Partners_Bool_Exp>>;
  _not?: InputMaybe<Partners_Bool_Exp>;
  _or?: InputMaybe<Array<Partners_Bool_Exp>>;
};

/** unique or primary key constraints on table "partners" */
export enum Partners_Constraint {
  /** unique or primary key constraint on columns "id" */
  ParterPkey = 'parter_pkey'
}

/** input type for inserting data into table "partners" */
export type Partners_Insert_Input = {
  content?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  organization?: InputMaybe<Scalars['String']['input']>;
};

/** response of any mutation on the table "partners" */
export type Partners_Mutation_Response = {
  __typename?: 'partners_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
};

/** on_conflict condition type for table "partners" */
export type Partners_On_Conflict = {
  constraint: Partners_Constraint;
  update_columns?: Array<Partners_Update_Column>;
  where?: InputMaybe<Partners_Bool_Exp>;
};

/** placeholder for update columns of table "partners" (current role has no relevant permissions) */
export enum Partners_Update_Column {
  /** placeholder (do not use) */
  Placeholder = '_PLACEHOLDER'
}

/** columns and relationships of "project_gallery_images" */
export type ProjectGalleryImages = {
  __typename?: 'projectGalleryImages';
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  order?: Maybe<Scalars['Int']['output']>;
  projectId?: Maybe<Scalars['Int']['output']>;
  /** A computed field, executes function "get_project_gallery_images_src" */
  src?: Maybe<Array<Images>>;
  title?: Maybe<Scalars['String']['output']>;
};


/** columns and relationships of "project_gallery_images" */
export type ProjectGalleryImagesSrcArgs = {
  distinct_on?: InputMaybe<Array<Images_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Images_Order_By>>;
  where?: InputMaybe<Images_Bool_Exp>;
};

/** order by aggregate values of table "project_gallery_images" */
export type ProjectGalleryImages_Aggregate_Order_By = {
  avg?: InputMaybe<ProjectGalleryImages_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<ProjectGalleryImages_Max_Order_By>;
  min?: InputMaybe<ProjectGalleryImages_Min_Order_By>;
  stddev?: InputMaybe<ProjectGalleryImages_Stddev_Order_By>;
  stddev_pop?: InputMaybe<ProjectGalleryImages_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<ProjectGalleryImages_Stddev_Samp_Order_By>;
  sum?: InputMaybe<ProjectGalleryImages_Sum_Order_By>;
  var_pop?: InputMaybe<ProjectGalleryImages_Var_Pop_Order_By>;
  var_samp?: InputMaybe<ProjectGalleryImages_Var_Samp_Order_By>;
  variance?: InputMaybe<ProjectGalleryImages_Variance_Order_By>;
};

/** order by avg() on columns of table "project_gallery_images" */
export type ProjectGalleryImages_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
  projectId?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "project_gallery_images". All fields are combined with a logical 'AND'. */
export type ProjectGalleryImages_Bool_Exp = {
  _and?: InputMaybe<Array<ProjectGalleryImages_Bool_Exp>>;
  _not?: InputMaybe<ProjectGalleryImages_Bool_Exp>;
  _or?: InputMaybe<Array<ProjectGalleryImages_Bool_Exp>>;
  description?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  order?: InputMaybe<Int_Comparison_Exp>;
  projectId?: InputMaybe<Int_Comparison_Exp>;
  src?: InputMaybe<Images_Bool_Exp>;
  title?: InputMaybe<String_Comparison_Exp>;
};

/** order by max() on columns of table "project_gallery_images" */
export type ProjectGalleryImages_Max_Order_By = {
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
  projectId?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "project_gallery_images" */
export type ProjectGalleryImages_Min_Order_By = {
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
  projectId?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
};

/** Ordering options when selecting data from "project_gallery_images". */
export type ProjectGalleryImages_Order_By = {
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
  projectId?: InputMaybe<Order_By>;
  src_aggregate?: InputMaybe<Images_Aggregate_Order_By>;
  title?: InputMaybe<Order_By>;
};

/** select columns of table "project_gallery_images" */
export enum ProjectGalleryImages_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  Order = 'order',
  /** column name */
  ProjectId = 'projectId',
  /** column name */
  Title = 'title'
}

/** order by stddev() on columns of table "project_gallery_images" */
export type ProjectGalleryImages_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
  projectId?: InputMaybe<Order_By>;
};

/** order by stddev_pop() on columns of table "project_gallery_images" */
export type ProjectGalleryImages_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
  projectId?: InputMaybe<Order_By>;
};

/** order by stddev_samp() on columns of table "project_gallery_images" */
export type ProjectGalleryImages_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
  projectId?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "projectGalleryImages" */
export type ProjectGalleryImages_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: ProjectGalleryImages_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type ProjectGalleryImages_Stream_Cursor_Value_Input = {
  description?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Scalars['Int']['input']>;
  projectId?: InputMaybe<Scalars['Int']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

/** order by sum() on columns of table "project_gallery_images" */
export type ProjectGalleryImages_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
  projectId?: InputMaybe<Order_By>;
};

/** order by var_pop() on columns of table "project_gallery_images" */
export type ProjectGalleryImages_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
  projectId?: InputMaybe<Order_By>;
};

/** order by var_samp() on columns of table "project_gallery_images" */
export type ProjectGalleryImages_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
  projectId?: InputMaybe<Order_By>;
};

/** order by variance() on columns of table "project_gallery_images" */
export type ProjectGalleryImages_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
  projectId?: InputMaybe<Order_By>;
};

/** columns and relationships of "projects" */
export type Projects = {
  __typename?: 'projects';
  bannerDescription?: Maybe<Scalars['String']['output']>;
  /** A computed field, executes function "get_project_banner_image" */
  bannerImage?: Maybe<Array<Images>>;
  bannerTitle?: Maybe<Scalars['String']['output']>;
  category?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  displayOnContribution?: Maybe<Scalars['Boolean']['output']>;
  displayOnCooperate?: Maybe<Scalars['Boolean']['output']>;
  firstContentLines?: Maybe<Array<Scalars['String']['output']>>;
  firstContentTitle?: Maybe<Scalars['String']['output']>;
  galleryContent?: Maybe<Scalars['String']['output']>;
  /** An array relationship */
  galleryImages: Array<ProjectGalleryImages>;
  id: Scalars['Int']['output'];
  isPin?: Maybe<Scalars['Boolean']['output']>;
  overviewFirstDescription?: Maybe<Scalars['String']['output']>;
  /** A computed field, executes function "get_project_overview_image" */
  overviewImage?: Maybe<Array<Images>>;
  overviewSecondDescription?: Maybe<Scalars['String']['output']>;
  pointsEffectiveness?: Maybe<Scalars['String']['output']>;
  pointsEffectivenessCovered?: Maybe<Scalars['String']['output']>;
  pointsEffectivenessPlanted?: Maybe<Scalars['String']['output']>;
  pointsMissionContent?: Maybe<Scalars['String']['output']>;
  pointsMissionTitle?: Maybe<Scalars['String']['output']>;
  pointsProjectLocation?: Maybe<Scalars['String']['output']>;
  pointsTimerangeFrom?: Maybe<Scalars['String']['output']>;
  pointsTimerangeTo?: Maybe<Scalars['String']['output']>;
  province?: Maybe<Scalars['String']['output']>;
  /** A computed field, executes function "get_project_second_content_image" */
  secondContentBackground?: Maybe<Array<Images>>;
  secondContentDescription?: Maybe<Scalars['String']['output']>;
  secondContentTitle?: Maybe<Scalars['String']['output']>;
  slogan?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  theme?: Maybe<Scalars['String']['output']>;
  /** A computed field, executes function "get_project_thumbnail_image" */
  thumbnail?: Maybe<Array<Images>>;
  title?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['timestamptz']['output']>;
};


/** columns and relationships of "projects" */
export type ProjectsBannerImageArgs = {
  distinct_on?: InputMaybe<Array<Images_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Images_Order_By>>;
  where?: InputMaybe<Images_Bool_Exp>;
};


/** columns and relationships of "projects" */
export type ProjectsGalleryImagesArgs = {
  distinct_on?: InputMaybe<Array<ProjectGalleryImages_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ProjectGalleryImages_Order_By>>;
  where?: InputMaybe<ProjectGalleryImages_Bool_Exp>;
};


/** columns and relationships of "projects" */
export type ProjectsOverviewImageArgs = {
  distinct_on?: InputMaybe<Array<Images_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Images_Order_By>>;
  where?: InputMaybe<Images_Bool_Exp>;
};


/** columns and relationships of "projects" */
export type ProjectsSecondContentBackgroundArgs = {
  distinct_on?: InputMaybe<Array<Images_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Images_Order_By>>;
  where?: InputMaybe<Images_Bool_Exp>;
};


/** columns and relationships of "projects" */
export type ProjectsThumbnailArgs = {
  distinct_on?: InputMaybe<Array<Images_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Images_Order_By>>;
  where?: InputMaybe<Images_Bool_Exp>;
};

/** aggregated selection of "projects" */
export type Projects_Aggregate = {
  __typename?: 'projects_aggregate';
  aggregate?: Maybe<Projects_Aggregate_Fields>;
  nodes: Array<Projects>;
};

/** aggregate fields of "projects" */
export type Projects_Aggregate_Fields = {
  __typename?: 'projects_aggregate_fields';
  avg?: Maybe<Projects_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Projects_Max_Fields>;
  min?: Maybe<Projects_Min_Fields>;
  stddev?: Maybe<Projects_Stddev_Fields>;
  stddev_pop?: Maybe<Projects_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Projects_Stddev_Samp_Fields>;
  sum?: Maybe<Projects_Sum_Fields>;
  var_pop?: Maybe<Projects_Var_Pop_Fields>;
  var_samp?: Maybe<Projects_Var_Samp_Fields>;
  variance?: Maybe<Projects_Variance_Fields>;
};


/** aggregate fields of "projects" */
export type Projects_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Projects_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type Projects_Avg_Fields = {
  __typename?: 'projects_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "projects". All fields are combined with a logical 'AND'. */
export type Projects_Bool_Exp = {
  _and?: InputMaybe<Array<Projects_Bool_Exp>>;
  _not?: InputMaybe<Projects_Bool_Exp>;
  _or?: InputMaybe<Array<Projects_Bool_Exp>>;
  bannerDescription?: InputMaybe<String_Comparison_Exp>;
  bannerImage?: InputMaybe<Images_Bool_Exp>;
  bannerTitle?: InputMaybe<String_Comparison_Exp>;
  category?: InputMaybe<String_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  displayOnContribution?: InputMaybe<Boolean_Comparison_Exp>;
  displayOnCooperate?: InputMaybe<Boolean_Comparison_Exp>;
  firstContentLines?: InputMaybe<String_Array_Comparison_Exp>;
  firstContentTitle?: InputMaybe<String_Comparison_Exp>;
  galleryContent?: InputMaybe<String_Comparison_Exp>;
  galleryImages?: InputMaybe<ProjectGalleryImages_Bool_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  isPin?: InputMaybe<Boolean_Comparison_Exp>;
  overviewFirstDescription?: InputMaybe<String_Comparison_Exp>;
  overviewImage?: InputMaybe<Images_Bool_Exp>;
  overviewSecondDescription?: InputMaybe<String_Comparison_Exp>;
  pointsEffectiveness?: InputMaybe<String_Comparison_Exp>;
  pointsEffectivenessCovered?: InputMaybe<String_Comparison_Exp>;
  pointsEffectivenessPlanted?: InputMaybe<String_Comparison_Exp>;
  pointsMissionContent?: InputMaybe<String_Comparison_Exp>;
  pointsMissionTitle?: InputMaybe<String_Comparison_Exp>;
  pointsProjectLocation?: InputMaybe<String_Comparison_Exp>;
  pointsTimerangeFrom?: InputMaybe<String_Comparison_Exp>;
  pointsTimerangeTo?: InputMaybe<String_Comparison_Exp>;
  province?: InputMaybe<String_Comparison_Exp>;
  secondContentBackground?: InputMaybe<Images_Bool_Exp>;
  secondContentDescription?: InputMaybe<String_Comparison_Exp>;
  secondContentTitle?: InputMaybe<String_Comparison_Exp>;
  slogan?: InputMaybe<String_Comparison_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
  theme?: InputMaybe<String_Comparison_Exp>;
  thumbnail?: InputMaybe<Images_Bool_Exp>;
  title?: InputMaybe<String_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** aggregate max on columns */
export type Projects_Max_Fields = {
  __typename?: 'projects_max_fields';
  bannerDescription?: Maybe<Scalars['String']['output']>;
  bannerTitle?: Maybe<Scalars['String']['output']>;
  category?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  firstContentLines?: Maybe<Array<Scalars['String']['output']>>;
  firstContentTitle?: Maybe<Scalars['String']['output']>;
  galleryContent?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  overviewFirstDescription?: Maybe<Scalars['String']['output']>;
  overviewSecondDescription?: Maybe<Scalars['String']['output']>;
  pointsEffectiveness?: Maybe<Scalars['String']['output']>;
  pointsEffectivenessCovered?: Maybe<Scalars['String']['output']>;
  pointsEffectivenessPlanted?: Maybe<Scalars['String']['output']>;
  pointsMissionContent?: Maybe<Scalars['String']['output']>;
  pointsMissionTitle?: Maybe<Scalars['String']['output']>;
  pointsProjectLocation?: Maybe<Scalars['String']['output']>;
  pointsTimerangeFrom?: Maybe<Scalars['String']['output']>;
  pointsTimerangeTo?: Maybe<Scalars['String']['output']>;
  province?: Maybe<Scalars['String']['output']>;
  secondContentDescription?: Maybe<Scalars['String']['output']>;
  secondContentTitle?: Maybe<Scalars['String']['output']>;
  slogan?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  theme?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['timestamptz']['output']>;
};

/** aggregate min on columns */
export type Projects_Min_Fields = {
  __typename?: 'projects_min_fields';
  bannerDescription?: Maybe<Scalars['String']['output']>;
  bannerTitle?: Maybe<Scalars['String']['output']>;
  category?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  firstContentLines?: Maybe<Array<Scalars['String']['output']>>;
  firstContentTitle?: Maybe<Scalars['String']['output']>;
  galleryContent?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  overviewFirstDescription?: Maybe<Scalars['String']['output']>;
  overviewSecondDescription?: Maybe<Scalars['String']['output']>;
  pointsEffectiveness?: Maybe<Scalars['String']['output']>;
  pointsEffectivenessCovered?: Maybe<Scalars['String']['output']>;
  pointsEffectivenessPlanted?: Maybe<Scalars['String']['output']>;
  pointsMissionContent?: Maybe<Scalars['String']['output']>;
  pointsMissionTitle?: Maybe<Scalars['String']['output']>;
  pointsProjectLocation?: Maybe<Scalars['String']['output']>;
  pointsTimerangeFrom?: Maybe<Scalars['String']['output']>;
  pointsTimerangeTo?: Maybe<Scalars['String']['output']>;
  province?: Maybe<Scalars['String']['output']>;
  secondContentDescription?: Maybe<Scalars['String']['output']>;
  secondContentTitle?: Maybe<Scalars['String']['output']>;
  slogan?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  theme?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['timestamptz']['output']>;
};

/** Ordering options when selecting data from "projects". */
export type Projects_Order_By = {
  bannerDescription?: InputMaybe<Order_By>;
  bannerImage_aggregate?: InputMaybe<Images_Aggregate_Order_By>;
  bannerTitle?: InputMaybe<Order_By>;
  category?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  displayOnContribution?: InputMaybe<Order_By>;
  displayOnCooperate?: InputMaybe<Order_By>;
  firstContentLines?: InputMaybe<Order_By>;
  firstContentTitle?: InputMaybe<Order_By>;
  galleryContent?: InputMaybe<Order_By>;
  galleryImages_aggregate?: InputMaybe<ProjectGalleryImages_Aggregate_Order_By>;
  id?: InputMaybe<Order_By>;
  isPin?: InputMaybe<Order_By>;
  overviewFirstDescription?: InputMaybe<Order_By>;
  overviewImage_aggregate?: InputMaybe<Images_Aggregate_Order_By>;
  overviewSecondDescription?: InputMaybe<Order_By>;
  pointsEffectiveness?: InputMaybe<Order_By>;
  pointsEffectivenessCovered?: InputMaybe<Order_By>;
  pointsEffectivenessPlanted?: InputMaybe<Order_By>;
  pointsMissionContent?: InputMaybe<Order_By>;
  pointsMissionTitle?: InputMaybe<Order_By>;
  pointsProjectLocation?: InputMaybe<Order_By>;
  pointsTimerangeFrom?: InputMaybe<Order_By>;
  pointsTimerangeTo?: InputMaybe<Order_By>;
  province?: InputMaybe<Order_By>;
  secondContentBackground_aggregate?: InputMaybe<Images_Aggregate_Order_By>;
  secondContentDescription?: InputMaybe<Order_By>;
  secondContentTitle?: InputMaybe<Order_By>;
  slogan?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  theme?: InputMaybe<Order_By>;
  thumbnail_aggregate?: InputMaybe<Images_Aggregate_Order_By>;
  title?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** select columns of table "projects" */
export enum Projects_Select_Column {
  /** column name */
  BannerDescription = 'bannerDescription',
  /** column name */
  BannerTitle = 'bannerTitle',
  /** column name */
  Category = 'category',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Description = 'description',
  /** column name */
  DisplayOnContribution = 'displayOnContribution',
  /** column name */
  DisplayOnCooperate = 'displayOnCooperate',
  /** column name */
  FirstContentLines = 'firstContentLines',
  /** column name */
  FirstContentTitle = 'firstContentTitle',
  /** column name */
  GalleryContent = 'galleryContent',
  /** column name */
  Id = 'id',
  /** column name */
  IsPin = 'isPin',
  /** column name */
  OverviewFirstDescription = 'overviewFirstDescription',
  /** column name */
  OverviewSecondDescription = 'overviewSecondDescription',
  /** column name */
  PointsEffectiveness = 'pointsEffectiveness',
  /** column name */
  PointsEffectivenessCovered = 'pointsEffectivenessCovered',
  /** column name */
  PointsEffectivenessPlanted = 'pointsEffectivenessPlanted',
  /** column name */
  PointsMissionContent = 'pointsMissionContent',
  /** column name */
  PointsMissionTitle = 'pointsMissionTitle',
  /** column name */
  PointsProjectLocation = 'pointsProjectLocation',
  /** column name */
  PointsTimerangeFrom = 'pointsTimerangeFrom',
  /** column name */
  PointsTimerangeTo = 'pointsTimerangeTo',
  /** column name */
  Province = 'province',
  /** column name */
  SecondContentDescription = 'secondContentDescription',
  /** column name */
  SecondContentTitle = 'secondContentTitle',
  /** column name */
  Slogan = 'slogan',
  /** column name */
  Status = 'status',
  /** column name */
  Theme = 'theme',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** aggregate stddev on columns */
export type Projects_Stddev_Fields = {
  __typename?: 'projects_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Projects_Stddev_Pop_Fields = {
  __typename?: 'projects_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Projects_Stddev_Samp_Fields = {
  __typename?: 'projects_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "projects" */
export type Projects_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Projects_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Projects_Stream_Cursor_Value_Input = {
  bannerDescription?: InputMaybe<Scalars['String']['input']>;
  bannerTitle?: InputMaybe<Scalars['String']['input']>;
  category?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  displayOnContribution?: InputMaybe<Scalars['Boolean']['input']>;
  displayOnCooperate?: InputMaybe<Scalars['Boolean']['input']>;
  firstContentLines?: InputMaybe<Array<Scalars['String']['input']>>;
  firstContentTitle?: InputMaybe<Scalars['String']['input']>;
  galleryContent?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  isPin?: InputMaybe<Scalars['Boolean']['input']>;
  overviewFirstDescription?: InputMaybe<Scalars['String']['input']>;
  overviewSecondDescription?: InputMaybe<Scalars['String']['input']>;
  pointsEffectiveness?: InputMaybe<Scalars['String']['input']>;
  pointsEffectivenessCovered?: InputMaybe<Scalars['String']['input']>;
  pointsEffectivenessPlanted?: InputMaybe<Scalars['String']['input']>;
  pointsMissionContent?: InputMaybe<Scalars['String']['input']>;
  pointsMissionTitle?: InputMaybe<Scalars['String']['input']>;
  pointsProjectLocation?: InputMaybe<Scalars['String']['input']>;
  pointsTimerangeFrom?: InputMaybe<Scalars['String']['input']>;
  pointsTimerangeTo?: InputMaybe<Scalars['String']['input']>;
  province?: InputMaybe<Scalars['String']['input']>;
  secondContentDescription?: InputMaybe<Scalars['String']['input']>;
  secondContentTitle?: InputMaybe<Scalars['String']['input']>;
  slogan?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  theme?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate sum on columns */
export type Projects_Sum_Fields = {
  __typename?: 'projects_sum_fields';
  id?: Maybe<Scalars['Int']['output']>;
};

/** aggregate var_pop on columns */
export type Projects_Var_Pop_Fields = {
  __typename?: 'projects_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Projects_Var_Samp_Fields = {
  __typename?: 'projects_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Projects_Variance_Fields = {
  __typename?: 'projects_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

export type Query_Root = {
  __typename?: 'query_root';
  /** fetch data from the table: "blogs" */
  blogs: Array<Blogs>;
  /** fetch aggregated fields from the table: "blogs" */
  blogsAggregate: Blogs_Aggregate;
  /** fetch data from the table: "blogs" using primary key columns */
  blogsByPk?: Maybe<Blogs>;
  /** fetch data from the table: "hirings" */
  hirings: Array<Hirings>;
  /** fetch aggregated fields from the table: "hirings" */
  hiringsAggregate: Hirings_Aggregate;
  /** fetch data from the table: "hirings" using primary key columns */
  hiringsByPk?: Maybe<Hirings>;
  /** fetch data from the table: "image_subtypes" */
  image_subtypes: Array<Image_Subtypes>;
  /** fetch aggregated fields from the table: "image_subtypes" */
  image_subtypes_aggregate: Image_Subtypes_Aggregate;
  /** fetch data from the table: "image_subtypes" using primary key columns */
  image_subtypes_by_pk?: Maybe<Image_Subtypes>;
  /** fetch data from the table: "image_types" */
  image_types: Array<Image_Types>;
  /** fetch aggregated fields from the table: "image_types" */
  image_types_aggregate: Image_Types_Aggregate;
  /** fetch data from the table: "image_types" using primary key columns */
  image_types_by_pk?: Maybe<Image_Types>;
  /** fetch data from the table: "images" */
  images: Array<Images>;
  /** fetch aggregated fields from the table: "images" */
  imagesAggregate: Images_Aggregate;
  /** fetch data from the table: "images" using primary key columns */
  imagesByPk?: Maybe<Images>;
  /** fetch data from the table: "kols" */
  kols: Array<Kols>;
  /** fetch aggregated fields from the table: "kols" */
  kolsAggregate: Kols_Aggregate;
  /** fetch data from the table: "kols" using primary key columns */
  kolsByPk?: Maybe<Kols>;
  /** fetch data from the table: "member_categories" */
  memberCategories: Array<MemberCategories>;
  /** fetch aggregated fields from the table: "member_categories" */
  memberCategoriesAggregate: MemberCategories_Aggregate;
  /** fetch data from the table: "member_categories" using primary key columns */
  memberCategoriesByPk?: Maybe<MemberCategories>;
  /** fetch data from the table: "members" */
  members: Array<Members>;
  /** fetch aggregated fields from the table: "members" */
  membersAggregate: Members_Aggregate;
  /** fetch data from the table: "members" using primary key columns */
  membersByPk?: Maybe<Members>;
  /** fetch data from the table: "project_gallery_images" */
  projectGalleryImages: Array<ProjectGalleryImages>;
  /** fetch data from the table: "project_gallery_images" using primary key columns */
  projectGalleryImagesByPk?: Maybe<ProjectGalleryImages>;
  /** fetch data from the table: "projects" */
  projects: Array<Projects>;
  /** fetch aggregated fields from the table: "projects" */
  projectsAggregate: Projects_Aggregate;
  /** fetch data from the table: "projects" using primary key columns */
  projectsByPk?: Maybe<Projects>;
};


export type Query_RootBlogsArgs = {
  distinct_on?: InputMaybe<Array<Blogs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Blogs_Order_By>>;
  where?: InputMaybe<Blogs_Bool_Exp>;
};


export type Query_RootBlogsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Blogs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Blogs_Order_By>>;
  where?: InputMaybe<Blogs_Bool_Exp>;
};


export type Query_RootBlogsByPkArgs = {
  id: Scalars['Int']['input'];
};


export type Query_RootHiringsArgs = {
  distinct_on?: InputMaybe<Array<Hirings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Hirings_Order_By>>;
  where?: InputMaybe<Hirings_Bool_Exp>;
};


export type Query_RootHiringsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Hirings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Hirings_Order_By>>;
  where?: InputMaybe<Hirings_Bool_Exp>;
};


export type Query_RootHiringsByPkArgs = {
  id: Scalars['Int']['input'];
};


export type Query_RootImage_SubtypesArgs = {
  distinct_on?: InputMaybe<Array<Image_Subtypes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Image_Subtypes_Order_By>>;
  where?: InputMaybe<Image_Subtypes_Bool_Exp>;
};


export type Query_RootImage_Subtypes_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Image_Subtypes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Image_Subtypes_Order_By>>;
  where?: InputMaybe<Image_Subtypes_Bool_Exp>;
};


export type Query_RootImage_Subtypes_By_PkArgs = {
  value: Scalars['String']['input'];
};


export type Query_RootImage_TypesArgs = {
  distinct_on?: InputMaybe<Array<Image_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Image_Types_Order_By>>;
  where?: InputMaybe<Image_Types_Bool_Exp>;
};


export type Query_RootImage_Types_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Image_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Image_Types_Order_By>>;
  where?: InputMaybe<Image_Types_Bool_Exp>;
};


export type Query_RootImage_Types_By_PkArgs = {
  value: Scalars['String']['input'];
};


export type Query_RootImagesArgs = {
  distinct_on?: InputMaybe<Array<Images_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Images_Order_By>>;
  where?: InputMaybe<Images_Bool_Exp>;
};


export type Query_RootImagesAggregateArgs = {
  distinct_on?: InputMaybe<Array<Images_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Images_Order_By>>;
  where?: InputMaybe<Images_Bool_Exp>;
};


export type Query_RootImagesByPkArgs = {
  id: Scalars['Int']['input'];
};


export type Query_RootKolsArgs = {
  distinct_on?: InputMaybe<Array<Kols_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Kols_Order_By>>;
  where?: InputMaybe<Kols_Bool_Exp>;
};


export type Query_RootKolsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Kols_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Kols_Order_By>>;
  where?: InputMaybe<Kols_Bool_Exp>;
};


export type Query_RootKolsByPkArgs = {
  id: Scalars['Int']['input'];
};


export type Query_RootMemberCategoriesArgs = {
  distinct_on?: InputMaybe<Array<MemberCategories_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<MemberCategories_Order_By>>;
  where?: InputMaybe<MemberCategories_Bool_Exp>;
};


export type Query_RootMemberCategoriesAggregateArgs = {
  distinct_on?: InputMaybe<Array<MemberCategories_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<MemberCategories_Order_By>>;
  where?: InputMaybe<MemberCategories_Bool_Exp>;
};


export type Query_RootMemberCategoriesByPkArgs = {
  id: Scalars['Int']['input'];
};


export type Query_RootMembersArgs = {
  distinct_on?: InputMaybe<Array<Members_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Members_Order_By>>;
  where?: InputMaybe<Members_Bool_Exp>;
};


export type Query_RootMembersAggregateArgs = {
  distinct_on?: InputMaybe<Array<Members_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Members_Order_By>>;
  where?: InputMaybe<Members_Bool_Exp>;
};


export type Query_RootMembersByPkArgs = {
  id: Scalars['Int']['input'];
};


export type Query_RootProjectGalleryImagesArgs = {
  distinct_on?: InputMaybe<Array<ProjectGalleryImages_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ProjectGalleryImages_Order_By>>;
  where?: InputMaybe<ProjectGalleryImages_Bool_Exp>;
};


export type Query_RootProjectGalleryImagesByPkArgs = {
  id: Scalars['Int']['input'];
};


export type Query_RootProjectsArgs = {
  distinct_on?: InputMaybe<Array<Projects_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Projects_Order_By>>;
  where?: InputMaybe<Projects_Bool_Exp>;
};


export type Query_RootProjectsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Projects_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Projects_Order_By>>;
  where?: InputMaybe<Projects_Bool_Exp>;
};


export type Query_RootProjectsByPkArgs = {
  id: Scalars['Int']['input'];
};

export type Subscription_Root = {
  __typename?: 'subscription_root';
  /** fetch data from the table: "blogs" */
  blogs: Array<Blogs>;
  /** fetch aggregated fields from the table: "blogs" */
  blogsAggregate: Blogs_Aggregate;
  /** fetch data from the table: "blogs" using primary key columns */
  blogsByPk?: Maybe<Blogs>;
  /** fetch data from the table in a streaming manner: "blogs" */
  blogs_stream: Array<Blogs>;
  /** fetch data from the table: "hirings" */
  hirings: Array<Hirings>;
  /** fetch aggregated fields from the table: "hirings" */
  hiringsAggregate: Hirings_Aggregate;
  /** fetch data from the table: "hirings" using primary key columns */
  hiringsByPk?: Maybe<Hirings>;
  /** fetch data from the table in a streaming manner: "hirings" */
  hirings_stream: Array<Hirings>;
  /** fetch data from the table: "image_subtypes" */
  image_subtypes: Array<Image_Subtypes>;
  /** fetch aggregated fields from the table: "image_subtypes" */
  image_subtypes_aggregate: Image_Subtypes_Aggregate;
  /** fetch data from the table: "image_subtypes" using primary key columns */
  image_subtypes_by_pk?: Maybe<Image_Subtypes>;
  /** fetch data from the table in a streaming manner: "image_subtypes" */
  image_subtypes_stream: Array<Image_Subtypes>;
  /** fetch data from the table: "image_types" */
  image_types: Array<Image_Types>;
  /** fetch aggregated fields from the table: "image_types" */
  image_types_aggregate: Image_Types_Aggregate;
  /** fetch data from the table: "image_types" using primary key columns */
  image_types_by_pk?: Maybe<Image_Types>;
  /** fetch data from the table in a streaming manner: "image_types" */
  image_types_stream: Array<Image_Types>;
  /** fetch data from the table: "images" */
  images: Array<Images>;
  /** fetch aggregated fields from the table: "images" */
  imagesAggregate: Images_Aggregate;
  /** fetch data from the table: "images" using primary key columns */
  imagesByPk?: Maybe<Images>;
  /** fetch data from the table in a streaming manner: "images" */
  images_stream: Array<Images>;
  /** fetch data from the table: "kols" */
  kols: Array<Kols>;
  /** fetch aggregated fields from the table: "kols" */
  kolsAggregate: Kols_Aggregate;
  /** fetch data from the table: "kols" using primary key columns */
  kolsByPk?: Maybe<Kols>;
  /** fetch data from the table in a streaming manner: "kols" */
  kols_stream: Array<Kols>;
  /** fetch data from the table: "member_categories" */
  memberCategories: Array<MemberCategories>;
  /** fetch aggregated fields from the table: "member_categories" */
  memberCategoriesAggregate: MemberCategories_Aggregate;
  /** fetch data from the table: "member_categories" using primary key columns */
  memberCategoriesByPk?: Maybe<MemberCategories>;
  /** fetch data from the table in a streaming manner: "member_categories" */
  memberCategories_stream: Array<MemberCategories>;
  /** fetch data from the table: "members" */
  members: Array<Members>;
  /** fetch aggregated fields from the table: "members" */
  membersAggregate: Members_Aggregate;
  /** fetch data from the table: "members" using primary key columns */
  membersByPk?: Maybe<Members>;
  /** fetch data from the table in a streaming manner: "members" */
  members_stream: Array<Members>;
  /** fetch data from the table: "project_gallery_images" */
  projectGalleryImages: Array<ProjectGalleryImages>;
  /** fetch data from the table: "project_gallery_images" using primary key columns */
  projectGalleryImagesByPk?: Maybe<ProjectGalleryImages>;
  /** fetch data from the table in a streaming manner: "project_gallery_images" */
  projectGalleryImages_stream: Array<ProjectGalleryImages>;
  /** fetch data from the table: "projects" */
  projects: Array<Projects>;
  /** fetch aggregated fields from the table: "projects" */
  projectsAggregate: Projects_Aggregate;
  /** fetch data from the table: "projects" using primary key columns */
  projectsByPk?: Maybe<Projects>;
  /** fetch data from the table in a streaming manner: "projects" */
  projects_stream: Array<Projects>;
};


export type Subscription_RootBlogsArgs = {
  distinct_on?: InputMaybe<Array<Blogs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Blogs_Order_By>>;
  where?: InputMaybe<Blogs_Bool_Exp>;
};


export type Subscription_RootBlogsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Blogs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Blogs_Order_By>>;
  where?: InputMaybe<Blogs_Bool_Exp>;
};


export type Subscription_RootBlogsByPkArgs = {
  id: Scalars['Int']['input'];
};


export type Subscription_RootBlogs_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Blogs_Stream_Cursor_Input>>;
  where?: InputMaybe<Blogs_Bool_Exp>;
};


export type Subscription_RootHiringsArgs = {
  distinct_on?: InputMaybe<Array<Hirings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Hirings_Order_By>>;
  where?: InputMaybe<Hirings_Bool_Exp>;
};


export type Subscription_RootHiringsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Hirings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Hirings_Order_By>>;
  where?: InputMaybe<Hirings_Bool_Exp>;
};


export type Subscription_RootHiringsByPkArgs = {
  id: Scalars['Int']['input'];
};


export type Subscription_RootHirings_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Hirings_Stream_Cursor_Input>>;
  where?: InputMaybe<Hirings_Bool_Exp>;
};


export type Subscription_RootImage_SubtypesArgs = {
  distinct_on?: InputMaybe<Array<Image_Subtypes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Image_Subtypes_Order_By>>;
  where?: InputMaybe<Image_Subtypes_Bool_Exp>;
};


export type Subscription_RootImage_Subtypes_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Image_Subtypes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Image_Subtypes_Order_By>>;
  where?: InputMaybe<Image_Subtypes_Bool_Exp>;
};


export type Subscription_RootImage_Subtypes_By_PkArgs = {
  value: Scalars['String']['input'];
};


export type Subscription_RootImage_Subtypes_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Image_Subtypes_Stream_Cursor_Input>>;
  where?: InputMaybe<Image_Subtypes_Bool_Exp>;
};


export type Subscription_RootImage_TypesArgs = {
  distinct_on?: InputMaybe<Array<Image_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Image_Types_Order_By>>;
  where?: InputMaybe<Image_Types_Bool_Exp>;
};


export type Subscription_RootImage_Types_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Image_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Image_Types_Order_By>>;
  where?: InputMaybe<Image_Types_Bool_Exp>;
};


export type Subscription_RootImage_Types_By_PkArgs = {
  value: Scalars['String']['input'];
};


export type Subscription_RootImage_Types_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Image_Types_Stream_Cursor_Input>>;
  where?: InputMaybe<Image_Types_Bool_Exp>;
};


export type Subscription_RootImagesArgs = {
  distinct_on?: InputMaybe<Array<Images_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Images_Order_By>>;
  where?: InputMaybe<Images_Bool_Exp>;
};


export type Subscription_RootImagesAggregateArgs = {
  distinct_on?: InputMaybe<Array<Images_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Images_Order_By>>;
  where?: InputMaybe<Images_Bool_Exp>;
};


export type Subscription_RootImagesByPkArgs = {
  id: Scalars['Int']['input'];
};


export type Subscription_RootImages_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Images_Stream_Cursor_Input>>;
  where?: InputMaybe<Images_Bool_Exp>;
};


export type Subscription_RootKolsArgs = {
  distinct_on?: InputMaybe<Array<Kols_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Kols_Order_By>>;
  where?: InputMaybe<Kols_Bool_Exp>;
};


export type Subscription_RootKolsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Kols_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Kols_Order_By>>;
  where?: InputMaybe<Kols_Bool_Exp>;
};


export type Subscription_RootKolsByPkArgs = {
  id: Scalars['Int']['input'];
};


export type Subscription_RootKols_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Kols_Stream_Cursor_Input>>;
  where?: InputMaybe<Kols_Bool_Exp>;
};


export type Subscription_RootMemberCategoriesArgs = {
  distinct_on?: InputMaybe<Array<MemberCategories_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<MemberCategories_Order_By>>;
  where?: InputMaybe<MemberCategories_Bool_Exp>;
};


export type Subscription_RootMemberCategoriesAggregateArgs = {
  distinct_on?: InputMaybe<Array<MemberCategories_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<MemberCategories_Order_By>>;
  where?: InputMaybe<MemberCategories_Bool_Exp>;
};


export type Subscription_RootMemberCategoriesByPkArgs = {
  id: Scalars['Int']['input'];
};


export type Subscription_RootMemberCategories_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<MemberCategories_Stream_Cursor_Input>>;
  where?: InputMaybe<MemberCategories_Bool_Exp>;
};


export type Subscription_RootMembersArgs = {
  distinct_on?: InputMaybe<Array<Members_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Members_Order_By>>;
  where?: InputMaybe<Members_Bool_Exp>;
};


export type Subscription_RootMembersAggregateArgs = {
  distinct_on?: InputMaybe<Array<Members_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Members_Order_By>>;
  where?: InputMaybe<Members_Bool_Exp>;
};


export type Subscription_RootMembersByPkArgs = {
  id: Scalars['Int']['input'];
};


export type Subscription_RootMembers_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Members_Stream_Cursor_Input>>;
  where?: InputMaybe<Members_Bool_Exp>;
};


export type Subscription_RootProjectGalleryImagesArgs = {
  distinct_on?: InputMaybe<Array<ProjectGalleryImages_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ProjectGalleryImages_Order_By>>;
  where?: InputMaybe<ProjectGalleryImages_Bool_Exp>;
};


export type Subscription_RootProjectGalleryImagesByPkArgs = {
  id: Scalars['Int']['input'];
};


export type Subscription_RootProjectGalleryImages_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<ProjectGalleryImages_Stream_Cursor_Input>>;
  where?: InputMaybe<ProjectGalleryImages_Bool_Exp>;
};


export type Subscription_RootProjectsArgs = {
  distinct_on?: InputMaybe<Array<Projects_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Projects_Order_By>>;
  where?: InputMaybe<Projects_Bool_Exp>;
};


export type Subscription_RootProjectsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Projects_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Projects_Order_By>>;
  where?: InputMaybe<Projects_Bool_Exp>;
};


export type Subscription_RootProjectsByPkArgs = {
  id: Scalars['Int']['input'];
};


export type Subscription_RootProjects_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Projects_Stream_Cursor_Input>>;
  where?: InputMaybe<Projects_Bool_Exp>;
};

/** Boolean expression to compare columns of type "timestamptz". All fields are combined with logical 'AND'. */
export type Timestamptz_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['timestamptz']['input']>;
  _gt?: InputMaybe<Scalars['timestamptz']['input']>;
  _gte?: InputMaybe<Scalars['timestamptz']['input']>;
  _in?: InputMaybe<Array<Scalars['timestamptz']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['timestamptz']['input']>;
  _lte?: InputMaybe<Scalars['timestamptz']['input']>;
  _neq?: InputMaybe<Scalars['timestamptz']['input']>;
  _nin?: InputMaybe<Array<Scalars['timestamptz']['input']>>;
};

export type PostCreatePartnerMutationVariables = Exact<{
  objects: Array<Partners_Insert_Input> | Partners_Insert_Input;
}>;


export type PostCreatePartnerMutation = { __typename?: 'mutation_root', insertPartners?: { __typename?: 'partners_mutation_response', affected_rows: number } | null };

export type PostCreateKolMutationVariables = Exact<{
  object: Kols_Insert_Input;
}>;


export type PostCreateKolMutation = { __typename?: 'mutation_root', insertKolsOne?: { __typename?: 'kols', id: number } | null };

export type GetAllBlogsQueryVariables = Exact<{
  where?: InputMaybe<Blogs_Bool_Exp>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<Blogs_Order_By> | Blogs_Order_By>;
}>;


export type GetAllBlogsQuery = { __typename?: 'query_root', blogs: Array<{ __typename?: 'blogs', id: number, title?: string | null, category?: string | null, createdAt?: any | null, shortContent?: string | null, thumbnail?: Array<{ __typename?: 'images', filename?: string | null }> | null }> };

export type GetBlogByIdQueryVariables = Exact<{
  blogsByPkId: Scalars['Int']['input'];
}>;


export type GetBlogByIdQuery = { __typename?: 'query_root', blogsByPk?: { __typename?: 'blogs', id: number, title?: string | null, category?: string | null, createdAt?: any | null, shortContent?: string | null, content: string, thumbnail?: Array<{ __typename?: 'images', filename?: string | null }> | null } | null };

export type GetHiringsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetHiringsQuery = { __typename?: 'query_root', hirings: Array<{ __typename?: 'hirings', description: string, title: string, id: number, image?: Array<{ __typename?: 'images', filename?: string | null }> | null }> };

export type GetHiringsByPkQueryVariables = Exact<{
  hiringsByPkId: Scalars['Int']['input'];
}>;


export type GetHiringsByPkQuery = { __typename?: 'query_root', hiringsByPk?: { __typename?: 'hirings', content?: string | null, description: string, title: string, createdAt?: any | null } | null };

export type GetKolsQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<Kols_Bool_Exp>;
}>;


export type GetKolsQuery = { __typename?: 'query_root', kols: Array<{ __typename?: 'kols', name: string, content: string, avatar?: Array<{ __typename?: 'images', filename?: string | null }> | null }> };

export type GetAllMembersQueryVariables = Exact<{
  orderBy?: InputMaybe<Array<Members_Order_By> | Members_Order_By>;
  where?: InputMaybe<Members_Bool_Exp>;
}>;


export type GetAllMembersQuery = { __typename?: 'query_root', members: Array<{ __typename?: 'members', name?: string | null, role?: string | null, memberCategoryId?: number | null, order?: number | null, avatar?: Array<{ __typename?: 'images', filename?: string | null }> | null, memberCategory?: { __typename?: 'memberCategories', name: string } | null }> };

export type GetMemberCategoriesQueryVariables = Exact<{
  orderBy?: InputMaybe<Array<MemberCategories_Order_By> | MemberCategories_Order_By>;
}>;


export type GetMemberCategoriesQuery = { __typename?: 'query_root', memberCategories: Array<{ __typename?: 'memberCategories', id: number, name: string }> };

export type GetProjectsQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<Projects_Bool_Exp>;
}>;


export type GetProjectsQuery = { __typename?: 'query_root', projects: Array<{ __typename?: 'projects', title?: string | null, id: number, bannerDescription?: string | null, province?: string | null, status?: string | null, thumbnail?: Array<{ __typename?: 'images', filename?: string | null }> | null }> };

export type GetProjectsByPkQueryVariables = Exact<{
  projectsByPkId: Scalars['Int']['input'];
}>;


export type GetProjectsByPkQuery = { __typename?: 'query_root', projectsByPk?: { __typename?: 'projects', id: number, title?: string | null, bannerTitle?: string | null, bannerDescription?: string | null, overviewFirstDescription?: string | null, overviewSecondDescription?: string | null, theme?: string | null, pointsMissionTitle?: string | null, pointsMissionContent?: string | null, pointsTimerangeFrom?: string | null, pointsTimerangeTo?: string | null, pointsProjectLocation?: string | null, pointsEffectivenessPlanted?: string | null, pointsEffectivenessCovered?: string | null, firstContentTitle?: string | null, firstContentLines?: Array<string> | null, secondContentDescription?: string | null, secondContentTitle?: string | null, galleryContent?: string | null, slogan?: string | null, bannerImage?: Array<{ __typename?: 'images', filename?: string | null }> | null, overviewImage?: Array<{ __typename?: 'images', filename?: string | null }> | null, secondContentBackground?: Array<{ __typename?: 'images', filename?: string | null }> | null, galleryImages: Array<{ __typename?: 'projectGalleryImages', title?: string | null, description?: string | null, src?: Array<{ __typename?: 'images', filename?: string | null }> | null }> } | null };


export const PostCreatePartnerDocument = gql`
    mutation postCreatePartner($objects: [partners_insert_input!]!) {
  insertPartners(objects: $objects) {
    affected_rows
  }
}
    `;
export type PostCreatePartnerMutationFn = Apollo.MutationFunction<PostCreatePartnerMutation, PostCreatePartnerMutationVariables>;

/**
 * __usePostCreatePartnerMutation__
 *
 * To run a mutation, you first call `usePostCreatePartnerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePostCreatePartnerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [postCreatePartnerMutation, { data, loading, error }] = usePostCreatePartnerMutation({
 *   variables: {
 *      objects: // value for 'objects'
 *   },
 * });
 */
export function usePostCreatePartnerMutation(baseOptions?: Apollo.MutationHookOptions<PostCreatePartnerMutation, PostCreatePartnerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PostCreatePartnerMutation, PostCreatePartnerMutationVariables>(PostCreatePartnerDocument, options);
      }
export type PostCreatePartnerMutationHookResult = ReturnType<typeof usePostCreatePartnerMutation>;
export type PostCreatePartnerMutationResult = Apollo.MutationResult<PostCreatePartnerMutation>;
export type PostCreatePartnerMutationOptions = Apollo.BaseMutationOptions<PostCreatePartnerMutation, PostCreatePartnerMutationVariables>;
export const PostCreateKolDocument = gql`
    mutation postCreateKol($object: kols_insert_input!) {
  insertKolsOne(object: $object) {
    id
  }
}
    `;
export type PostCreateKolMutationFn = Apollo.MutationFunction<PostCreateKolMutation, PostCreateKolMutationVariables>;

/**
 * __usePostCreateKolMutation__
 *
 * To run a mutation, you first call `usePostCreateKolMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePostCreateKolMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [postCreateKolMutation, { data, loading, error }] = usePostCreateKolMutation({
 *   variables: {
 *      object: // value for 'object'
 *   },
 * });
 */
export function usePostCreateKolMutation(baseOptions?: Apollo.MutationHookOptions<PostCreateKolMutation, PostCreateKolMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PostCreateKolMutation, PostCreateKolMutationVariables>(PostCreateKolDocument, options);
      }
export type PostCreateKolMutationHookResult = ReturnType<typeof usePostCreateKolMutation>;
export type PostCreateKolMutationResult = Apollo.MutationResult<PostCreateKolMutation>;
export type PostCreateKolMutationOptions = Apollo.BaseMutationOptions<PostCreateKolMutation, PostCreateKolMutationVariables>;
export const GetAllBlogsDocument = gql`
    query getAllBlogs($where: blogs_bool_exp, $limit: Int, $orderBy: [blogs_order_by!]) {
  blogs(where: $where, limit: $limit, order_by: $orderBy) {
    id
    thumbnail {
      filename
    }
    title
    category
    createdAt
    shortContent
  }
}
    `;

/**
 * __useGetAllBlogsQuery__
 *
 * To run a query within a React component, call `useGetAllBlogsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllBlogsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllBlogsQuery({
 *   variables: {
 *      where: // value for 'where'
 *      limit: // value for 'limit'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useGetAllBlogsQuery(baseOptions?: Apollo.QueryHookOptions<GetAllBlogsQuery, GetAllBlogsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllBlogsQuery, GetAllBlogsQueryVariables>(GetAllBlogsDocument, options);
      }
export function useGetAllBlogsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllBlogsQuery, GetAllBlogsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllBlogsQuery, GetAllBlogsQueryVariables>(GetAllBlogsDocument, options);
        }
export function useGetAllBlogsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetAllBlogsQuery, GetAllBlogsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetAllBlogsQuery, GetAllBlogsQueryVariables>(GetAllBlogsDocument, options);
        }
export type GetAllBlogsQueryHookResult = ReturnType<typeof useGetAllBlogsQuery>;
export type GetAllBlogsLazyQueryHookResult = ReturnType<typeof useGetAllBlogsLazyQuery>;
export type GetAllBlogsSuspenseQueryHookResult = ReturnType<typeof useGetAllBlogsSuspenseQuery>;
export type GetAllBlogsQueryResult = Apollo.QueryResult<GetAllBlogsQuery, GetAllBlogsQueryVariables>;
export const GetBlogByIdDocument = gql`
    query getBlogById($blogsByPkId: Int!) {
  blogsByPk(id: $blogsByPkId) {
    id
    thumbnail {
      filename
    }
    title
    category
    createdAt
    shortContent
    content
  }
}
    `;

/**
 * __useGetBlogByIdQuery__
 *
 * To run a query within a React component, call `useGetBlogByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBlogByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBlogByIdQuery({
 *   variables: {
 *      blogsByPkId: // value for 'blogsByPkId'
 *   },
 * });
 */
export function useGetBlogByIdQuery(baseOptions: Apollo.QueryHookOptions<GetBlogByIdQuery, GetBlogByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetBlogByIdQuery, GetBlogByIdQueryVariables>(GetBlogByIdDocument, options);
      }
export function useGetBlogByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBlogByIdQuery, GetBlogByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetBlogByIdQuery, GetBlogByIdQueryVariables>(GetBlogByIdDocument, options);
        }
export function useGetBlogByIdSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetBlogByIdQuery, GetBlogByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetBlogByIdQuery, GetBlogByIdQueryVariables>(GetBlogByIdDocument, options);
        }
export type GetBlogByIdQueryHookResult = ReturnType<typeof useGetBlogByIdQuery>;
export type GetBlogByIdLazyQueryHookResult = ReturnType<typeof useGetBlogByIdLazyQuery>;
export type GetBlogByIdSuspenseQueryHookResult = ReturnType<typeof useGetBlogByIdSuspenseQuery>;
export type GetBlogByIdQueryResult = Apollo.QueryResult<GetBlogByIdQuery, GetBlogByIdQueryVariables>;
export const GetHiringsDocument = gql`
    query getHirings {
  hirings {
    description
    image {
      filename
    }
    title
    id
  }
}
    `;

/**
 * __useGetHiringsQuery__
 *
 * To run a query within a React component, call `useGetHiringsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetHiringsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetHiringsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetHiringsQuery(baseOptions?: Apollo.QueryHookOptions<GetHiringsQuery, GetHiringsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetHiringsQuery, GetHiringsQueryVariables>(GetHiringsDocument, options);
      }
export function useGetHiringsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetHiringsQuery, GetHiringsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetHiringsQuery, GetHiringsQueryVariables>(GetHiringsDocument, options);
        }
export function useGetHiringsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetHiringsQuery, GetHiringsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetHiringsQuery, GetHiringsQueryVariables>(GetHiringsDocument, options);
        }
export type GetHiringsQueryHookResult = ReturnType<typeof useGetHiringsQuery>;
export type GetHiringsLazyQueryHookResult = ReturnType<typeof useGetHiringsLazyQuery>;
export type GetHiringsSuspenseQueryHookResult = ReturnType<typeof useGetHiringsSuspenseQuery>;
export type GetHiringsQueryResult = Apollo.QueryResult<GetHiringsQuery, GetHiringsQueryVariables>;
export const GetHiringsByPkDocument = gql`
    query getHiringsByPk($hiringsByPkId: Int!) {
  hiringsByPk(id: $hiringsByPkId) {
    content
    description
    title
    createdAt
  }
}
    `;

/**
 * __useGetHiringsByPkQuery__
 *
 * To run a query within a React component, call `useGetHiringsByPkQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetHiringsByPkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetHiringsByPkQuery({
 *   variables: {
 *      hiringsByPkId: // value for 'hiringsByPkId'
 *   },
 * });
 */
export function useGetHiringsByPkQuery(baseOptions: Apollo.QueryHookOptions<GetHiringsByPkQuery, GetHiringsByPkQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetHiringsByPkQuery, GetHiringsByPkQueryVariables>(GetHiringsByPkDocument, options);
      }
export function useGetHiringsByPkLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetHiringsByPkQuery, GetHiringsByPkQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetHiringsByPkQuery, GetHiringsByPkQueryVariables>(GetHiringsByPkDocument, options);
        }
export function useGetHiringsByPkSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetHiringsByPkQuery, GetHiringsByPkQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetHiringsByPkQuery, GetHiringsByPkQueryVariables>(GetHiringsByPkDocument, options);
        }
export type GetHiringsByPkQueryHookResult = ReturnType<typeof useGetHiringsByPkQuery>;
export type GetHiringsByPkLazyQueryHookResult = ReturnType<typeof useGetHiringsByPkLazyQuery>;
export type GetHiringsByPkSuspenseQueryHookResult = ReturnType<typeof useGetHiringsByPkSuspenseQuery>;
export type GetHiringsByPkQueryResult = Apollo.QueryResult<GetHiringsByPkQuery, GetHiringsByPkQueryVariables>;
export const GetKolsDocument = gql`
    query getKols($limit: Int, $where: kols_bool_exp) {
  kols(limit: $limit, where: $where) {
    name
    content
    avatar {
      filename
    }
  }
}
    `;

/**
 * __useGetKolsQuery__
 *
 * To run a query within a React component, call `useGetKolsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetKolsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetKolsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetKolsQuery(baseOptions?: Apollo.QueryHookOptions<GetKolsQuery, GetKolsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetKolsQuery, GetKolsQueryVariables>(GetKolsDocument, options);
      }
export function useGetKolsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetKolsQuery, GetKolsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetKolsQuery, GetKolsQueryVariables>(GetKolsDocument, options);
        }
export function useGetKolsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetKolsQuery, GetKolsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetKolsQuery, GetKolsQueryVariables>(GetKolsDocument, options);
        }
export type GetKolsQueryHookResult = ReturnType<typeof useGetKolsQuery>;
export type GetKolsLazyQueryHookResult = ReturnType<typeof useGetKolsLazyQuery>;
export type GetKolsSuspenseQueryHookResult = ReturnType<typeof useGetKolsSuspenseQuery>;
export type GetKolsQueryResult = Apollo.QueryResult<GetKolsQuery, GetKolsQueryVariables>;
export const GetAllMembersDocument = gql`
    query getAllMembers($orderBy: [members_order_by!], $where: members_bool_exp) {
  members(order_by: $orderBy, where: $where) {
    name
    avatar {
      filename
    }
    memberCategory {
      name
    }
    role
    memberCategoryId
    order
  }
}
    `;

/**
 * __useGetAllMembersQuery__
 *
 * To run a query within a React component, call `useGetAllMembersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllMembersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllMembersQuery({
 *   variables: {
 *      orderBy: // value for 'orderBy'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetAllMembersQuery(baseOptions?: Apollo.QueryHookOptions<GetAllMembersQuery, GetAllMembersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllMembersQuery, GetAllMembersQueryVariables>(GetAllMembersDocument, options);
      }
export function useGetAllMembersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllMembersQuery, GetAllMembersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllMembersQuery, GetAllMembersQueryVariables>(GetAllMembersDocument, options);
        }
export function useGetAllMembersSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetAllMembersQuery, GetAllMembersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetAllMembersQuery, GetAllMembersQueryVariables>(GetAllMembersDocument, options);
        }
export type GetAllMembersQueryHookResult = ReturnType<typeof useGetAllMembersQuery>;
export type GetAllMembersLazyQueryHookResult = ReturnType<typeof useGetAllMembersLazyQuery>;
export type GetAllMembersSuspenseQueryHookResult = ReturnType<typeof useGetAllMembersSuspenseQuery>;
export type GetAllMembersQueryResult = Apollo.QueryResult<GetAllMembersQuery, GetAllMembersQueryVariables>;
export const GetMemberCategoriesDocument = gql`
    query getMemberCategories($orderBy: [memberCategories_order_by!]) {
  memberCategories(order_by: $orderBy) {
    id
    name
  }
}
    `;

/**
 * __useGetMemberCategoriesQuery__
 *
 * To run a query within a React component, call `useGetMemberCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMemberCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMemberCategoriesQuery({
 *   variables: {
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useGetMemberCategoriesQuery(baseOptions?: Apollo.QueryHookOptions<GetMemberCategoriesQuery, GetMemberCategoriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMemberCategoriesQuery, GetMemberCategoriesQueryVariables>(GetMemberCategoriesDocument, options);
      }
export function useGetMemberCategoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMemberCategoriesQuery, GetMemberCategoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMemberCategoriesQuery, GetMemberCategoriesQueryVariables>(GetMemberCategoriesDocument, options);
        }
export function useGetMemberCategoriesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetMemberCategoriesQuery, GetMemberCategoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetMemberCategoriesQuery, GetMemberCategoriesQueryVariables>(GetMemberCategoriesDocument, options);
        }
export type GetMemberCategoriesQueryHookResult = ReturnType<typeof useGetMemberCategoriesQuery>;
export type GetMemberCategoriesLazyQueryHookResult = ReturnType<typeof useGetMemberCategoriesLazyQuery>;
export type GetMemberCategoriesSuspenseQueryHookResult = ReturnType<typeof useGetMemberCategoriesSuspenseQuery>;
export type GetMemberCategoriesQueryResult = Apollo.QueryResult<GetMemberCategoriesQuery, GetMemberCategoriesQueryVariables>;
export const GetProjectsDocument = gql`
    query getProjects($limit: Int, $where: projects_bool_exp) {
  projects(limit: $limit, where: $where) {
    thumbnail {
      filename
    }
    title
    id
    bannerDescription
    province
    status
  }
}
    `;

/**
 * __useGetProjectsQuery__
 *
 * To run a query within a React component, call `useGetProjectsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProjectsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProjectsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetProjectsQuery(baseOptions?: Apollo.QueryHookOptions<GetProjectsQuery, GetProjectsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetProjectsQuery, GetProjectsQueryVariables>(GetProjectsDocument, options);
      }
export function useGetProjectsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetProjectsQuery, GetProjectsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetProjectsQuery, GetProjectsQueryVariables>(GetProjectsDocument, options);
        }
export function useGetProjectsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetProjectsQuery, GetProjectsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetProjectsQuery, GetProjectsQueryVariables>(GetProjectsDocument, options);
        }
export type GetProjectsQueryHookResult = ReturnType<typeof useGetProjectsQuery>;
export type GetProjectsLazyQueryHookResult = ReturnType<typeof useGetProjectsLazyQuery>;
export type GetProjectsSuspenseQueryHookResult = ReturnType<typeof useGetProjectsSuspenseQuery>;
export type GetProjectsQueryResult = Apollo.QueryResult<GetProjectsQuery, GetProjectsQueryVariables>;
export const GetProjectsByPkDocument = gql`
    query getProjectsByPk($projectsByPkId: Int!) {
  projectsByPk(id: $projectsByPkId) {
    id
    title
    bannerTitle
    bannerDescription
    bannerImage {
      filename
    }
    overviewFirstDescription
    overviewSecondDescription
    overviewImage {
      filename
    }
    theme
    pointsMissionTitle
    pointsMissionContent
    pointsTimerangeFrom
    pointsTimerangeTo
    pointsProjectLocation
    pointsEffectivenessPlanted
    pointsEffectivenessCovered
    firstContentTitle
    firstContentLines
    secondContentBackground {
      filename
    }
    secondContentDescription
    secondContentTitle
    galleryContent
    slogan
    galleryImages {
      title
      description
      src {
        filename
      }
    }
  }
}
    `;

/**
 * __useGetProjectsByPkQuery__
 *
 * To run a query within a React component, call `useGetProjectsByPkQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProjectsByPkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProjectsByPkQuery({
 *   variables: {
 *      projectsByPkId: // value for 'projectsByPkId'
 *   },
 * });
 */
export function useGetProjectsByPkQuery(baseOptions: Apollo.QueryHookOptions<GetProjectsByPkQuery, GetProjectsByPkQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetProjectsByPkQuery, GetProjectsByPkQueryVariables>(GetProjectsByPkDocument, options);
      }
export function useGetProjectsByPkLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetProjectsByPkQuery, GetProjectsByPkQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetProjectsByPkQuery, GetProjectsByPkQueryVariables>(GetProjectsByPkDocument, options);
        }
export function useGetProjectsByPkSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetProjectsByPkQuery, GetProjectsByPkQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetProjectsByPkQuery, GetProjectsByPkQueryVariables>(GetProjectsByPkDocument, options);
        }
export type GetProjectsByPkQueryHookResult = ReturnType<typeof useGetProjectsByPkQuery>;
export type GetProjectsByPkLazyQueryHookResult = ReturnType<typeof useGetProjectsByPkLazyQuery>;
export type GetProjectsByPkSuspenseQueryHookResult = ReturnType<typeof useGetProjectsByPkSuspenseQuery>;
export type GetProjectsByPkQueryResult = Apollo.QueryResult<GetProjectsByPkQuery, GetProjectsByPkQueryVariables>;